<template>
  <!-- 我是执法上报 -->
  <div class="patrol">
    <el-container class="patrol-container">
      <el-header class="patrol-header">
        <div class="block">
          <span class="demonstration">开始时间：</span>
          <el-date-picker
            class="datainput"
            value-format="yyyy/MM/dd hh-mm-ss"
            prefix-icon="el-icon-date"
            v-model="starttime"
            type="datetime"
            placeholder="选择日期时间"
            default-time="12:00:00"
          >
          </el-date-picker>
        </div>
        <div class="block">
          <span class="demonstration">结束时间：</span>
          <el-date-picker
            class="datainput"
            value-format="yyyy/MM/dd hh-mm-ss"
            prefix-icon="el-icon-date"
            v-model="endtime"
            type="datetime"
            placeholder="选择日期时间"
            default-time="12:00:00"
          >
          </el-date-picker>
        </div>
        <div class="block">
          <!-- <span class="demonstration">结束时间：</span> -->
          <el-row class="demo-autocomplete">
            <el-col :span="24">
              <span class="demonstration">海域海岛名称：</span>
              <el-autocomplete
                class="inline-input"
                v-model="state2"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                @select="handleSelect"
              ></el-autocomplete>
            </el-col>
          </el-row>
        </div>
        <div class="block">
          <el-button
            type="primary"
            size="mini"
            class="commonbtn"
            @click="quertlist"
            >搜索</el-button
          >
          <el-button
            type="primary"
            size="mini"
            class="commonbtn"
            @click="openinfo($event)"
            >上报</el-button
          >
        </div>

        <!-- dialog -->

        <el-dialog
          title="海域海岛执法上报"
          :visible.sync="dialogFormVisible"
          :modal-append-to-body="false"
          @close="closeDialog"
          width="50%"
        >
          <div class="line"></div>
          <div class="dialog-nav">
            <div class="dialog-navl">
              <div class="navlbox">
                <span class="navl-text navl-to">海域海岛名称：</span
                ><input class="navl-input" type="text" v-model="islandname" />
              </div>
              <div class="navlbox">
                <span class="navl-text navl-tt">执法时间:</span
                ><input
                  class="navl-input"
                  type="text"
                  disabled
                  v-model="nowtime"
                />
              </div>
              <div class="navlbox">
                <span class="navl-text navl-tt">执法人员:</span
                ><input class="navl-input" type="text" v-model="person" />
              </div>
              <div class="navlbox">
                <span class="navl-textarea">执法情况说明：</span
                >
                  <!-- id="textarea" -->
                <textarea
                  name=""
                  class="navl-input"
                  cols="30"
                  rows="10"
                  v-model="patrolDescription"
                ></textarea>
              </div>
            </div>
            <div class="dialog-navr">
              <div class="navrbox">
                <div class="nb-ol">
                  <span class="navl-text">图片上传：</span>
                </div>
                <div class="nb-or">
                  <el-upload
                    ref="pictureUpload"
                    :limit="1"
                    :action = url
                    list-type="picture-card"
                    class="el-upload"
                    :on-success="handleSuccess"
                  >
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{ file }">
                      <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url"
                        alt=""
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-preview"
                          @click="handlePictureCardPreview(file)"
                        >
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <!-- <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span> -->
                        <span
                          v-if="!disabled"
                          class="el-upload-list__item-delete"
                          @click="handleRemove(file)"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                  <el-dialog
                    :visible.sync="dialogVisible2"
                    :modal-append-to-body="false"
                  >
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </div>
              </div>
              <div class="navrbox">
                <div class="nb-ol">
                  <span class="navl-text">文件上传：</span>
                </div>
                <div class="nb-or">
                  <el-upload
                    :on-success="handleSuccesstext"
                    ref="pictureUploada"
                    :limit="1"
                    :action = url
                    list-type="picture-card"
                    class="el-upload"
                  >
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{ file }">
                      <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url_b"
                        alt=""
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-preview"
                          @click="handlePictureCardPreview(file)"
                        >
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <!-- <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span> -->
                        <span
                          v-if="!disabled"
                          class="el-upload-list__item-delete"
                          @click="handleRemovea(file)"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </div>

                <el-dialog
                  :visible.sync="dialogVisible"
                  :modal-append-to-body="false"
                >
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
              <div class="navrbox">
                <div class="nb-ol">
                  <span class="navl-text">巡视视频：</span>
                </div>

                <div class="nb-or">
                  <el-upload
                    :on-success="handleSuccessVideo"
                    ref="pictureUploadb"
                    :limit="3"
                    :action = url
                    list-type="picture-card"
                    class="el-upload"
                  >
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{ file }">
                      <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url_c"
                        alt=""
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-preview"
                          @click="handlePictureCardPreviewvideo(file)"
                        >
                          <i class="el-icon-zoom-in"></i>
                        </span>
                        <!-- <span
                          v-if="!disabled"
                          class="el-upload-list__item-delete"
                          @click="handleDownload(file)"
                        >
                          <i class="el-icon-download"></i>
                        </span> -->
                        <span
                          v-if="!disabled"
                          class="el-upload-list__item-delete"
                          @click="handleRemoveb(file)"
                        >
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </div>
                <el-dialog
                  :visible.sync="dialogVisible"
                  :modal-append-to-body="false"
                >
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="updatebtn" v-show="surebtn"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </el-header>

      <el-main class="patrol-main">
        <el-table
          border
          stripe
          @row-click="handdle"
          :row-style="{ height: '45px' }"
          :cell-style="{ padding: '0' }"
          :data="islandList"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            header-align="center"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="value"
            label="海域海岛名称"
          >
          </el-table-column>
          <el-table-column
            prop="patrolTime"
            label="执法时间"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="patrolPerson"
            label="执法人员"
          >
          </el-table-column>
          <el-table-column header-align="center" align="center" label="操作">
            <template slot-scope="scope">
              <span
                class="table-operation"
                style="padding-right:13px"
                @click="dialogVisible = true"
                ><el-button type="primary" size="mini">详情</el-button>
                <el-dialog
                  :append-to-body="true"
                  title="海域海岛执法详情"
                  :visible.sync="dialogVisible"
                  width="50%"
                  style="text-align:center;line-height:52px;"
                >
                  <div class="line"></div>
                  <div class="dialog-nav">
                    <div class="dialog-navl">
                      <div class="navlbox">
                        <span class="navl-text navl-to">海域海岛名称：</span
                        ><input
                          class="navl-input"
                          type="text"
                          disabled
                          v-model="islandname"
                        />
                      </div>
                      <div class="navlbox">
                        <span class="navl-text navl-tt">执法时间:</span
                        ><input
                          class="navl-input"
                          type="text"
                          disabled
                          v-model="nowtime"
                        />
                      </div>
                      <div class="navlbox">
                        <span class="navl-text navl-tt">执法人员:</span
                        ><input
                          class="navl-input"
                          disabled
                          type="text"
                          v-model="person"
                        />
                      </div>
                      <div>
                        <span class="navl-textarea">执法情况说明：</span
                        ><textarea
                          name=""
                          id="textarea"
                          cols="30"
                          rows="10"
                          disabled
                          v-model="patrolDescription"
                        ></textarea>
                      </div>
                    </div>
                    <div class="dialog-navr">
                      <div class="navrbox">
                        <div class="nb-ol">
                          <span
                          class="navtext navl-text">图片详情：</span>
                        </div>
                        <div class="nb-or">
                          <img :src="img" alt="" class="el-upload upimg">
                        </div>
                      </div>
                      <div class="navrbox">
                        <div class="nb-ol" @click="aalert()">
                          <span class="navtext
                          navl-text"
                          >文件详情：</span>
                          <span style="padding-left: 207px;width:60px;    white-space: nowrap;
                          cursor: pointer;" class=";">
                            {{patrolFiles}}
                          </span>
                        </div>
                        <div class="nb-or">
                          <!-- <img src="" alt="" class="el-upload"> -->
                        </div>
                      </div>
                      <div class="navrbox">
                        <div class="nv-ol" @click="aalert()">
                          <span class="navtext navl-text">视频详情：</span>
                          <span style="padding-left: 207px;width:60px;    white-space: nowrap;
                          cursor: pointer;" class=";">
                            {{patrolVedios}}
                          </span>
                        </div>
                        <div class="nb-or">
                          <!-- <img src="" alt="" class="el-upload"> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- dialog弹出框底部           -->
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible = false"
                      >确 定</el-button
                    >
                  </span>
                </el-dialog>
              </span>
              <el-popconfirm
                title="确定删除吗？"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="deltable(scope.row)"
              >
                <span slot="reference" class="table-operation-del"><el-button type="primary" size="mini">删除</el-button></span>
                <!-- <span slot="reference" class="table-operation-del" @click="deltable(scope.row)"
                >删除</span> -->
                <!-- <el-button >删除</el-button> -->
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-main>

      <el-footer class="patrol-footer">
        <div class="block">
          <!-- <span class="demonstration">完整功能</span> -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[
              currentPage1,
              currentPage2,
              currentPage3,
              currentPage4
            ]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalnumb"
          >
          </el-pagination>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import api from "@/util/api";
import baseAdmin from "@/util/api";
export default {
  name: "patrol",
  data() {
    return {
      img:'',
      handleSuccessUrl:'',
      handleSuccessVurl:'',
      handleSuccesTurl:'',
      dialogVisibledetail: false,
      dialogFormVisible: false,
      dialogVisible2: false,
      starttime: "",
      endtime: "",
      state2: "",
      statename: "", // el选择器中的数据
      islandList: [],
      // 表格绑定的数据
      islandname: "",
      person: "",
      pageNo: "1",
      pageSize: 20,
      totalnumb:null,
      patrolDescription: "",
      currentPage1: 20,
      currentPage2: 30,
      currentPage3: 40,
      currentPage4: 50,
      page_size: "",
      // dialog
      nowtime: "",
      imageUrl: "",
      dialogImageUrl: "",
      file: {
        url_a: "",
        url_b: "",
        url_c: ""
      },
      disabled: false,
      dialogVisible: false,
      // 确定按钮
      surebtn: true,
      uploadParams: "",
      url:'',
      patrolFiles:'',
      patrolVedios:'',
    };
  },
  mounted() {
    this.url = api.addfile
    this.loadAll();
    // this.restaurants = this.loadAll();
    this.gettime();
  },
  methods: {
    openInfodialog() {
      this.dialogVisibledd = true;
    },

    closeDialog() {
      this.islandname = "";
      this.nowtime = this.gettime();
      this.person = "";
    },
    // 删除接口
    deltable(row) {
      const params = new FormData();
      params.append("id", row.id);
      this.$axios
        .post(api.delIslandPatrol, params)
        .then(res => {
          this.loadAll();
          this.$message({
            message: "删除成功",
            type: "success"
          });
        })
        .catch(err => {
          this.$message.error("删除失败");
        });
    },
    gettime() {
      Date.prototype.Format = function(fmt) {
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "H+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          S: this.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
        return fmt;
      };

      var a = new Date().Format("yyyy-MM-dd HH:mm:ss");

      return (this.nowtime = a);
    },
    querySearch(queryString, cb) {
      var islandList = this.islandList;
      var results = queryString
        ? islandList.filter(this.createFilter(queryString))
        : islandList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    aalert(){
      this.$message.error("无权限下载")
    },
    createFilter(queryString) {
      return restaurant => {
        // console.log(
        //   islandList.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
        //     0
        // );
        return (
          this.islandList.value
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    loadAll() {
      const params = new FormData();
      params.append("pageNo", this.pageNo);
      params.append("pageSize", this.pageSize);
      this.$axios
        .post(api.SeaIslandPatrol, params)
        .then(res => {
          this.totalnumb = res.data.result.total
          // this.page_size = this.totalnumb /
          var aa = res.data.result.records;
          var islandList = [];
          aa.forEach(item => {
            islandList.push({
              id: item.id,
              value: item.seaIslandName,
              patrolTime: item.patrolTime,
              patrolPerson: item.patrolPerson,
              patrolDescription: item.patrolDescription,
              patrolImages:item.patrolImages,
              patrolFiles:item.patrolFiles,
              patrolVedios:item.patrolVedios
            });
          });
          this.islandList = islandList;
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleSelect(item) {
    },
    // 分页器的方法
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log(`每页 ${val} 条`);
      const params = new FormData();
      params.append("pageNo", this.pageNo);
      params.append("pageSize", this.pageSize);
      // 每次更改请求数据，后期可优化
      this.$axios
        .post(api.SeaIslandPatrol, params)
        .then(res => {
          var aa = res.data.result.records;
          var islandList = [];
          aa.forEach(item => {
            islandList.push({
              value: item.seaIslandName,
              patrolTime: item.patrolTime,
              patrolPerson: item.patrolPerson
            });
          });
          this.islandList = islandList;
        })
        .catch(err => {
          // console.log(err);
        });
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);

      this.pageNo = val;
      const params = new FormData();
      params.append("pageNo", this.pageNo);
      params.append("pageSize", this.pageSize);
      this.$axios
        .post(api.SeaIslandPatrol, params)
        .then(res => {
          var aa = res.data.result.records;
          var islandList = [];
          aa.forEach(item => {
            islandList.push({
              value: item.seaIslandName,
              patrolTime: item.patrolTime,
              patrolPerson: item.patrolPerson
            });
          });
          this.islandList = islandList;
        })
        .catch(err => {
          // console.log(err);
        });
    },
    // 点击搜索，搜索数据？
    quertlist() {
      const params = new FormData();
      params.append("startTime", this.starttime);
      params.append("endTime", this.endtime);
      params.append("islandName", this.state2);
      let sd = api.SeaIslandlist;
      this.$axios
        .post(api.SeaIslandlist, params)
        .then(res => {
          // console.log(res, "res");
          var aa = res.data.result;
          // console.log(aa);
          if (aa) {
            //暂无数据？
            var islandList = [];
            aa.forEach(item => {
              islandList.push({
                value: item.seaIslandName,
                // patrolTime: item.patrolTime,
                // patrolTime: item.patrolTime,
                patrolPerson: item.patrolPerson
              });
            });
            // console.log(islandList);
            this.islandList = islandList;
          } else {
            this.islandList = [];
          }
        })
        .catch(err => {
          // console.log(err);
        });
    },

    // 上传图片
    handleRemove(file) {
      // console.log(file);
      this.$refs.pictureUpload.handleRemove(file);
    },
    handleRemovea(file) {
      // console.log(file);
      this.$refs.pictureUploada.handleRemove(file);
    },
    handleRemoveb(file) {
      // console.log(file);
      this.$refs.pictureUploadb.handleRemove(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      // console.log(this.dialogImageUrl,'dialogImageUrl');
      this.dialogVisible2 = true;
    },
    handleDownload(file) {
      // console.log(file);
    },

    // 上传之后的回调
    handleSuccess(res, file, fileList){
      // console.log(res);
      this.handleSuccessUrl =''
      this.handleSuccessUrl = 'http://192.168.2.201:8082/sea-island' + res.result.filePath
    },
    handleSuccessVideo(res, file, fileList){
      this.handleSuccessVurl=''
      this.handleSuccessVurl = 'http://192.168.2.201:8082/sea-island' + res.result.filePath
    },
    handleSuccesstext(res, file, fileList){
      this.handleSuccesTurl =''
      this.handleSuccesTurl = 'http://192.168.2.201:8082/sea-island' + res.result.filePath
    },
    openinfo($event) {
      // this.dialogVisibledetail = true
      this.dialogFormVisible = true;
      if ($event.currentTarget.tagName === "SPAN") {
        this.surebtn = false;
      }
      if ($event.currentTarget.tagName === "BUTTON") {
        this.surebtn = true;
      }
    },
    handdle(row, event, column) {
      this.img = 'http://' + row.patrolImages
      this.islandname = row.value;
      this.nowtime = row.patrolTime;
      this.person = row.patrolPerson;
      this.patrolDescription = row.patrolDescription;
      this.patrolFiles = row.patrolFiles.substring(row.patrolFiles.length -20,row.patrolFiles.length)
      this.patrolVedios = row.patrolVedios.substring(row.patrolVedios.length - 20,row.patrolVedios.length)
    },
    updatebtn() {
      this.dialogFormVisible = false;

      const params = new FormData();
      params.append("patrolDescription", this.patrolDescription);
      params.append("patrolPerson ", this.person);
      params.append("patrolTime", this.nowtime);
      params.append("patrolImages ", this.handleSuccessUrl);
      params.append("patrolVedios", this.handleSuccessVurl);
      params.append("seaIslandName", this.islandname);
      params.append('patrolFiles',this.handleSuccesTurl)
      this.$axios
        .post(api.addIslandPatrol, params)
        .then(res => {
          if (res.data.code === '501'){
            this.$message.error("上传格式错误");
          }
          else if(res.data.code !== '200' || res.status !== 200) {
            this.$message.error("上传失败");
          }else{
            this.loadAll();

            this.$message({
              message: "上传成功!",
              type: "success"
            });
          }
        })
    }
  }
};
</script>

<style scoped lang="scss">
.patrol {
  padding-top: 10px;
  font-family: PingFang SC;
  height: 100%;
  width: 100%;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

}
.el-header {
  background-color: #fff;
  color: #333;
  text-align: center;
  line-height: 60px;
  padding: 0;
}
.el-footer {
  background-color: #f4f7f9;
  color: #333;
  height: 60px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-main {
  padding: 0px;
}
.patrol-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 100%;
  height: 100%;
}
body > .el-container {
  margin-bottom: 40px;
  height: 100%;
  width: 100%;
}
::v-deep .el-date-editor {
  position: relative;
  .el-input__prefix {
    display: none;
  }
}
.datainput {
  width: 200px;
  height: 34px;
}
.block {
  padding-left: 22px;
  font-size: 13px;
}
.block ::v-deep .el-input__inner {
  height: 34px;
}

.patrol-container {
  height: calc(100% - 40px);
  width: 100%;
}
.patrol-header {
  display: flex;
  justify-content: flex-start;
  // padding-left: 590px;
}
.commonbtn {
  height: 35px;
  align-self: center;
}
.table-operation {
  cursor: pointer;
  padding-right: 13px;
  color: rgb(64, 163, 243);
}
.table-operation-del {
  cursor: pointer;
  color: rgb(64, 163, 243);
}
.dialog-nav {
  display: flex;
}
.dialog-navl {
  width: 50%;
}
.navl-text {
  width: 50px;
  position: absolute;
  left: 92px;
}
.navl-textarea {

  position: relative;
    left: -13px;
}
.navl-box {
  position: absolute;
}
.navl-to {
  position: relative;
  left: -12px;
}
.navl-tt {
  position: relative;
  left: -32px;
}
.navl-input {
  width: 200px;
  float: right;
  margin-right: 60px;
  height: 25px;
  margin-top: 14px;
}
#textarea {
  position: relative;
  left: 67px;
  top: 12px;
  height: 25px;
  width: 202px;
}
#textarea {
  outline-color: #4792ee;
  max-width: 202px;
  min-width: 202px;
  min-height: 25px;
}
.navl-input:focus {
  outline-color: #4792ee;
  width: 200px;
}
.dialog-navr {
  width: 50%;
  position: relative;
}

// el
.el-dialog__header {
  padding: 0;
  margin: 0;
  line-height: 40px;
  text-align: left;
  text-indent: 50px;
}
.el-dialog__headerbtn {
  top: 15px;
}
::v-deep .el-dialog__header {
  height: 26px;
  padding: 0;
}
.el-upload {
  // position: absolute;
  position: relative;
  left: 160px;
  height: 32px;
}

.el-upload ::v-deep .el-upload--picture-card,
.el-upload ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  line-height: 60px;
  font-size: 12px;
  height: 50px;
  width: 50px;
  background: #f8f8f8;
  border: 1px solid #d2d2d2;
  border-radius: 0;
}
.line {
  width: 100%;
  height: 1px;
  background: #167fff;
  margin-bottom: 20px;
}
.navrbox {
  width: 290px;
  height: 80px;
}

.nb-or {
  width: 200px;
}
.navtext {
  width: 100px;
}
.upimg{
  height: 69px;
  width: 69px;
}
</style>
